import React from 'react';
import SEO from '../components/seo';
import { Flex, Box, Heading, Text } from 'rebass';
import Divider from '../components/divider';
import Wrapper from '../components/wrapper';
import { ThemeProvider } from 'emotion-theming';
import theme from '../components/theme';
// @ts-ignore
import GridLogoBlack from '../assets/svgs/GridLogoBlack.svg';
import { navigate } from '@reach/router';

const NotFoundPage = () => (
  <ThemeProvider theme={theme}>
    <SEO title="404: Not found" />
    <Wrapper>
      <Box margin={'auto'}>
        <Flex as={'nav'} height={85} alignItems={'center'}>
          <GridLogoBlack
            onClick={() => {
              navigate('/', {
                state: { overlay: { visible: false, path: null } },
              });
            }}
            css={{
              cursor: 'pointer',
              height: '100%',
            }}
          />
        </Flex>
        <Divider />
        <Box py={68}>
          <Heading variant={'text.h700MediumUpdated'} mb={24}>
            You’ve ended up on a page that doesn’t exist
          </Heading>
          <Text variant={'text.h400Regular'}>
            We hope we can help you starting on our{' '}
            <a href="/" style={{ color: '#a2e300' }}>
              home page
            </a>
          </Text>
        </Box>
      </Box>
    </Wrapper>
  </ThemeProvider>
);

export default NotFoundPage;
